// Swiper JS
import Swiper, { Navigation } from 'swiper';
import Inputmask from "inputmask";

Swiper.use([Navigation]);
const form = document.getElementById("modalForm");
const modal = document.querySelector('#modalOrder');
const notification = document.querySelector('.modal-form__notification');

const initSlider = () => {
    let swiperSolutions = new Swiper('.slider-solutions', {
        navigation: {
            nextEl: '.swiper-button-next.slider-arrow__solutions',
            prevEl: '.swiper-button-prev.slider-arrow__solutions',
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 3,
            },

        }
    });

    swiperSolutions.on('click', (swiper, event) => {
        let target = event.target;
        if (target.classList.contains("btn-openModal")) {
            let btn = target;
            btn.addEventListener('click', openModal);
        }
    });


    new Swiper('.slider-sertificates', {
        navigation: {
            nextEl: '.swiper-button-next.slider-arrow__sertificates',
            prevEl: '.swiper-button-prev.slider-arrow__sertificates',
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 0,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 50,
            },

        }
    })
}

function openModal() {
    let scrollbar = document.body.clientWidth - window.innerWidth + 'px';
    document.querySelector('.modal').classList.add('show');
    modal.style.marginLeft = scrollbar;
}

function closeModal() {
    document.querySelector('.modal').classList.remove('show');
    modal.style.marginLeft = '0px';
    document.querySelector('.modal-form__notification').innerText = '';
    notification.classList.remove('show');
}

function setHeight() {
    let height = document.querySelector(".header").clientHeight + 'px';
    document.querySelector(".section-first").style.height = `calc(100vh - ${height}`;
}
document.querySelector('.btn-openModal').addEventListener('click', openModal);
document.querySelector('svg.icon-close').addEventListener('click', closeModal);
initSlider();
setHeight();


form.addEventListener('submit', function (e) {
    e.preventDefault();

    const payload = new FormData(form);
    let params = {};
    payload.forEach((value, key) => params[key] = value);

    fetch('/api/sendmail', {
        method: "POST",
        body: JSON.stringify(params),
        headers: { "content-type": "application/json; charset=utf-8" }
    })
        .then(result => {
            result.json(result)
                .then(result => {
                    // console.log('result: ', result);
                    notification.innerText = result.message;
                    notification.classList.add('show');
                    form.reset();
                }).catch(err => {
                    console.log('err json: ', err);
                })

        }).catch(err => {
            // console.log('err: ', err);
            notification.innerText = err.message;
        })
});

var phone = document.getElementById("inp-phone");
Inputmask("+7 (999) 999-99-99").mask(phone);

document.addEventListener("DOMContentLoaded", () => {
    document.querySelectorAll("[data-fancybox='gallery']").forEach(link => {
        const img = link.querySelector("img");
        if (img) {
            link.href = img.src; // Берем путь из <img> и вставляем в <a>
        }
    });
});